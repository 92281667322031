//import router from "page";
import { find, get, once, pick } from "lodash-es";
import { property } from "./util/propertystores";
import Search from "./Search.svelte";
import { geo, items } from "@parkingboss/svelte-utils";
import { derived } from "svelte/store";
import { writable } from "@parkingboss/svelte-storage";

import "../styles/app.fonts.scss";
import "../styles/app.scss";

// needs to run before legacy
import init from "./init";
import { api } from "./util/api";
import { sign, logout } from "./util/auth";

import { router, state, end } from "./util/router";

//import legacy...
import _legacy from "./legacy";

//if (!api.isLoggedIn()) api.logIn(true);

const recent = writable({}, "fieldagent_recentitems");

const searcher = new Search({
  target: document.querySelector("nav.dynamic"),
  props: {
    items,
    recent: derived([recent, items], ([$recent, $state]) => {
      return Object.values($recent || {})
        .sort((a, b) => b.ts - a.ts)
        .map((item) => {
          return (
            $state[item.value] ||
            find($state, {
              type: item.type,
              key: item.value.toUpperCase(),
            }) || {
              key: item.value.toUpperCase(),
              display: item.value.toUpperCase(),
              type: item.type,
            }
          );
        })
        .filter((item) => !!item);
    }),
  },
});

router("/:property/reports", function (ctx) {
  // off to reports land
  window.location.href = sign(
    "https://reports.communityboss.app/" + ctx.params.property
  );
  //window.location.href = "https://my.communityboss.app/user/navigate?url=https://reports.parkingboss.com/" + ctx.params.property;
});

router("/:property/manager", function (ctx) {
  // off to mgr land
  window.location.href = sign(
    "https://manager.communityboss.app/properties/" + ctx.params.property
  );
  //window.location.href = "https://my.communityboss.app/user/navigate?url=https://fieldagent.parkingboss.com/" + ctx.params.property + "?manager=true";
});

router("/", function (ctx, next) {
  window.location.href = "https://my.communityboss.app/";
});

router("/:location/manage", function (ctx, next) {
  router.redirect("/" + ctx.params.location + "/permits/vehicles");
});

router("/help", function (ctx, next) {
  window.location.href = "https://help.communityboss.app";
});

router("/:location", function (ctx, next) {
  router.redirect("/" + ctx.params.location + "/permits/vehicles");
});

property.subscribe(($property) =>
  document.documentElement.classList.toggle(
    "reports",
    $property && $property.reports
  )
);

// startup!
router.start({
  dispatch: true,
  popstate: true,
  click: true,
  hashbang: false,
});

function coordsWithHeading(gps, compass) {
  const p = get(gps, "position.coords");
  const h = get(compass, "orientation");
  if (!p && !h) return {};
  return Object.assign(
    {},
    pick(p, [
      "latitude",
      "longitude",
      "accuracy",
      "heading",
      "headingAccuracy",
      "speed",
      "altitude",
      "altitudeAccuracy",
    ]),
    pick(h, ["heading", "headingAccuracy"])
  );
}

const setupMonitorPosition = once(function () {
  geo.subscribe(($geo) => {
    if (!window.ParkIQ || !window.ParkIQ.API) return;
    window.ParkIQ.API.position = coordsWithHeading($geo);
  });
});

window.addEventListener("click", setupMonitorPosition);
