<script>
  import {
    blobToImageData,
    connectStream,
    getBarcode,
    getFrame,
    imageDataToBlob,
    onFrame,
  } from "@parkingboss/barcam";
  import debug from "debug";
  import { createEventDispatcher, onMount } from "svelte";

  const log = debug("boss:field-agent:Camera");

  const dispatch = createEventDispatcher();

  // Component API
  export function scrollIntoView(opts) {
    figureEl?.scrollIntoView(opts);
  }

  // Params
  let mode;
  export { mode as class };
  export let cover = null;
  export let noUpload = false;
  export let barcode = false;

  // Element Bindings
  let figureEl = null;
  let videoEl = null;

  // State
  let loading = true;
  let fallback = false;
  let stopStream = () => {};

  $: if (videoEl) startStream();

  let stopWatching = () => {};
  $: if (videoEl && !loading && !fallback && barcode) {
    stopWatching = onFrame(videoEl, "fitted", async (imgData) => {
      log("onframe", imgData);
      const result = await getBarcode(imgData);
      if (result) {
        fireBarcode({ barcode: result, blob: await imageDataToBlob(imgData) });
      }
    });
  } else {
    stopWatching();
    stopWatching = () => {};
  }

  function startStream() {
    // Stop the stream
    stopStream();

    // Reset the state
    loading = true;
    fallback = false;
    stopStream = () => {};

    // Start the Stream
    connectStream(videoEl)
      .then(async ({ disconnect }) => {
        stopStream = () => {
          stopWatching();
          disconnect();
        };
      })
      .catch((err) => {
        console.warn("Error getting stream; fallback to be used", err);
        fallback = true;
      })
      .finally(() => {
        loading = false;
      });
  }

  function onVisibilityChange() {
    if (document.hidden) {
      stopStream();
    } else {
      startStream();
    }
  }

  onMount(() => {
    log("mounted");
    document.addEventListener("visibilitychange", onVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", onVisibilityChange);
      stopStream();
    };
  });

  // Event Handlers:
  async function onFileChanged(evt) {
    const blob = evt.target.files[0];
    if (!blob) return;

    fireFrame({ blob });

    if (barcode) {
      const imgData = await blobToImageData(blob);
      const result = await getBarcode(imgData);
      fireBarcode({ barcode: result, blob });
    }
  }

  async function onClick(evt) {
    if (cover) {
      return;
    }

    const imgData = await getFrame(videoEl, null);
    const blob = await imageDataToBlob(imgData);

    fireFrame({ blob });

    if (barcode) {
      const result = await getBarcode(imgData);
      fireBarcode({ barcode: result, blob });
    }
  }

  // Event Dispatchers
  function fireFrame({ blob }) {
    dispatch("frame", { blob });
  }

  function fireBarcode({ barcode, blob }) {
    dispatch("barcode", { barcode, blob });
  }

  $: log("loading:", loading);
  $: log("fallback:", fallback);
  $: log("barcode:", barcode);
</script>

<figure
  class="camera {mode}"
  bind:this={figureEl}
  class:loading
  class:video={!fallback}
>
  {#if fallback}
    <input
      type="file"
      accept="image/*"
      capture="environment"
      on:change={onFileChanged}
    />
  {:else if !loading}
    <button type="button" on:click={onClick} />
  {/if}

  <img src={cover || ""} alt="scanning preview" />

  {#if !noUpload}
    <input
      type="file"
      accept="image/*;capture=camera"
      on:change={onFileChanged}
    />
  {/if}

  <video bind:this={videoEl} playsinline autoplay muted />

  {#if !loading && !fallback}
    <figcaption>
      <slot />
    </figcaption>
  {/if}
</figure>
