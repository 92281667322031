import Record from "../components/Record.svelte";

const recordid = new Record({
  target: document.querySelector("main[data-record]"),
  anchor: document.querySelector("main[data-record] > dl"),
  props: {
    item: null,
    url: false,
  },
});

(function (page, postal, api, root, container) {
  var location = null;
  var selected = null;

  var _init = _.once(function (ctx) {
    // not sure what we do here...
  });

  var setup = function (ctx, next) {
    _init(ctx);
    next();
  };

  var cal = {
    sameDay: "ddd h:mm A",
    nextDay: "ddd h:mm A",
    nextWeek: "ddd h:mm A",
    lastDay: "ddd MMM D h:mm A",
    lastWeek: "ddd MMM D h:mm A",
    sameElse: "MMM D YYYY h:mm A",
  };

  var generated = {
    sameDay: "ddd MMM D h:mm A [(]Z[)]",
    nextDay: "ddd MMM D h:mm A [(]Z[)]",
    nextWeek: "ddd MMM D h:mm A [(]Z[)]",
    lastDay: "ddd MMM D h:mm A [(]Z[)]",
    lastWeek: "ddd MMM D h:mm A [(]Z[)]",
    sameElse: "MMM D YYYY h:mm A [(]Z[)]",
  };

  var _details = _.template(
    '<% var t = _.filter([ "id", _.get(record, "type", ""), _.get(record, "item.type.format", ""), _.get(record, "item.format", "") ]).join(" ") %><dt class="id"></dt><dd class="id"><h1><data class="<%= t %>"><%- _.get(record, "item.display", "") %><% if(record?.item?.color) { %><data class="color" value="<%= record.item.color.toLowerCase() %>"></data><% } %><% _.each(["capacity:charging", "capacity:disabled", "capacity:lift", "size"].filter((key) => !!record?.item?.[key]), function(key) { %><data class="<%= key.replace(":", "-") %>" value="<%= record?.item?.[key] %>"/><% }) %></data></h1></dd>',
    {
      variable: "record",
    }
  );

  var details = container.querySelector("dl"); // this will always be the first header (" > ")

  var view = function (record) {
    //header.innerHTML = _header(record || {});

    //details.innerHTML = _details(record || {});
    recordid.$set({
      item: record?.item,
    });

    container.className = _.filter([
      _.get(record, "type", ""),
      _.get(record, "item.type.format", ""),
    ]).join(" ");

    _.each(container.querySelectorAll("a[rel='latest']"), function (a) {
      var id = _.get(record, "item.id");
      var latest = _.get(record, "item.latest");

      if (!!id && !!latest && id !== latest)
        a.setAttribute(
          "href",
          window.location.pathname.substr(
            0,
            window.location.pathname.lastIndexOf("/")
          ) +
            "/" +
            latest
        );
      else a.removeAttribute("href");
    });

    var time = container.querySelector("time[data-valid]");
    time.setAttribute("datetime", _.get(record, "generated", ""));
    time.dispatchEvent(new CustomEvent("change", { bubbles: true }));

    if (!record) {
      _.each(container.querySelectorAll("form"), function (form) {
        form.reset();
      });
    }

    //console.log(record);

    container.querySelector("ul.similar").innerHTML = _.reduce(
      _.get(record, "similar", []),
      function (html, item) {
        return (
          '<li><a href="' +
          window.location.pathname.substr(
            0,
            window.location.pathname.lastIndexOf("/")
          ) +
          "/" +
          item.id +
          '"><data value="' +
          item.id +
          '" class="id ' +
          _.get(record, "type", "") +
          '">' +
          item.display +
          "</data></a></li>"
        );
      },
      ""
    );

    _.each(["activity", "history"], function (key) {
      if (!record) {
        // empty
        var list = container.querySelector("ul[data-records='" + key + "']");
        while (list.firstChild) list.firstChild.remove();
        list.classList.remove("empty");
        return;
      }

      var list = container.querySelector("ul[data-records='" + key + "']");

      //var items = _.sortBy(collection, "vehicle.display");
      var type = record[key];
      var items = type.items;

      //console.log(items);

      list.innerHTML = _.reduce(
        items,
        function (html, item) {
          return html + api.Templates[item.__type](item);
        },
        ""
      );
      list.classList[list.hasChildNodes() ? "remove" : "add"]("empty");
      list.classList[!!type.partial ? "add" : "remove"]("partial");
    });

    _.each(
      container.querySelectorAll("input[type='hidden']"),
      function (input) {
        input.setAttribute("name", !!record ? record.type : "container");
        input.setAttribute(
          "value",
          !!record ? record.item.id || record.item.key : ""
        );
      }
    );
  };

  var updated = null;

  var checkSelected = function (id, key, subject) {
    if (!selected) return true;

    // check selected against both id and key
    if (selected != id && selected != subject && selected.toUpperCase() != key)
      return false; // no longer selected

    selected = id || subject || key;

    if (!!id) {
      //var serialpart = "/" + item.key.toUpperCase();
      //var path = window.location.pathname;

      const inUrl = window.location.pathname.split("/").pop();

      // match
      const match = !![id, subject, key].find(
        (i) => i == inUrl || i == inUrl.toUpperCase()
      );

      if (match && inUrl != id) {
        page.redirect(
          window.location.pathname.substring(
            0,
            window.location.pathname.lastIndexOf("/") + 1
          ) + id
        );
        return false;
      }
    }

    return true;
  };

  var record = function (record) {
    if (!record || !record.item) return record;

    var gen = new Date(record.generated || record.ts);

    if (!checkSelected(record.item.id, record.item.key, record.item.subject))
      return; // no longer selected

    if (!!updated && updated.getTime() >= gen.getTime());

    view(record);

    _.each(
      document.querySelectorAll(
        "aside[data-record] input[name='" + record.type + "']"
      ),
      function (input) {
        var val = _.get(record, "item.display", "");
        if (input.getAttribute("value") === val) return; // previously set...
        input.setAttribute("value", (input.value = val));
        input.dispatchEvent(new CustomEvent("change", { bubbles: true }));
      }
    );

    updated = gen;

    return record;
  };

  var vehicle = function (location, id, subset) {
    return api.Vehicles.get(location, id, subset).then(record);
  };

  var tenant = function (location, id, subset) {
    return api.Tenants.get(location, id, subset).then(record);
  };

  var space = function (location, id, subset) {
    return api.Spaces.get(location, id, subset).then(record);
  };

  var media = function (location, id, subset) {
    return api.Media.get(location, id, subset).then(record);
  };

  function update() {
    if (!location) return;
    if (!selected) return;
    if (root.matches("[data-record^='vehicle']"))
      vehicle(location, selected, false);
    if (root.matches("[data-record^='tenant']"))
      tenant(location, selected, false);
    if (root.matches("[data-record^='space']"))
      space(location, selected, false);
    if (root.matches("[data-record^='media']"))
      media(location, selected, false);
    //console.log("update", new Date().toISOString());
  }

  var polling = {
    timer: null,
    interval: 1.5 * 60 * 1000,
    wait: 30 * 1000,
  };
  _.extend(polling, {
    callback: _.throttle(update, polling.wait),
    start: function () {
      polling.timer =
        polling.timer ||
        window.requestInterval(polling.callback, polling.interval);
    },
    stop: function () {
      if (!!polling.timer) window.clearRequestInterval(polling.timer);
      polling.timer = null;
    },
  });

  _.each(["/:location/vehicles/:vehicle"], function (path) {
    page(path, function (ctx, next) {
      // show a specific violation

      // reset

      if (ctx.params.vehicle != selected) view();

      //ts = null; // clear the timestamp?

      vehicle(
        (location = ctx.params.location),
        (selected = ctx.params.vehicle),
        true
      ).then(function () {
        return vehicle(location, selected, false); // reprocess with no subset
      });

      root.setAttribute("data-record", "vehicles/" + selected);
      container.setAttribute("data-record", "vehicles/" + selected);

      polling.start();
    });
    page.exit(path, function (ctx, next) {
      polling.stop();
      view();
      _.each(
        document.querySelectorAll("aside[data-record] input[name='vehicle']"),
        function (input) {
          input.setAttribute("value", "");
          input.dispatchEvent(new CustomEvent("change", { bubbles: true }));
        }
      );
      next();
    });
  });

  _.each(["/:location/tenants/:tenant"], function (path) {
    page(path, function (ctx, next) {
      // reset

      if (ctx.params.tenant != selected) view();

      //ts = null; // clear the timestamp?

      tenant(
        (location = ctx.params.location),
        (selected = ctx.params.tenant),
        true
      ).then(function () {
        return tenant(location, selected, false); // reprocess with no subset
      });

      root.setAttribute("data-record", "tenants/" + selected);
      container.setAttribute("data-record", "tenants/" + selected);

      polling.start();
    });
    page.exit(path, function (ctx, next) {
      polling.stop();
      view();
      _.each(
        document.querySelectorAll("aside[data-record] input[name='tenant']"),
        function (input) {
          input.setAttribute("value", "");
          input.dispatchEvent(new CustomEvent("change", { bubbles: true }));
        }
      );
      next();
    });
  });

  _.each(["/:location/spaces/:space"], function (path) {
    page(path, function (ctx, next) {
      if (ctx.params.space != selected) view();

      space(
        (location = ctx.params.location),
        (selected = ctx.params.space),
        true
      ).then(function () {
        return space(location, selected, false); // reprocess with no subset
      });

      root.setAttribute("data-record", "spaces/" + selected);
      container.setAttribute("data-record", "spaces/" + selected);

      polling.start();
    });
    page.exit(path, function (ctx, next) {
      polling.stop();
      view();
      _.each(
        document.querySelectorAll("aside[data-record] input[name='space']"),
        function (input) {
          input.setAttribute("value", "");
          input.dispatchEvent(new CustomEvent("change", { bubbles: true }));
        }
      );
      next();
    });
  });

  _.each(["/:location/media/:media"], function (path) {
    page(path, function (ctx, next) {
      if (ctx.params.media != selected) view();

      media(
        (location = ctx.params.location),
        (selected = ctx.params.media),
        true
      ).then(function () {
        return media(location, selected, false); // reprocess with no subset
      });

      root.setAttribute("data-record", "media/" + selected);
      container.setAttribute("data-record", "media/" + selected);

      polling.start();
    });
    page.exit(path, function (ctx, next) {
      polling.stop();
      view();
      _.each(
        document.querySelectorAll("aside[data-record] input[name='media']"),
        function (input) {
          input.setAttribute("value", "");
          input.dispatchEvent(new CustomEvent("change", { bubbles: true }));
        }
      );
      next();
    });
  });

  postal.subscribe({
    topic: "note.created",
    callback: function (data, envelope) {
      //var _ts = new Date(data.generated || data.ts);

      //console.log(last + ", " + ts);

      //if(!!updated && updated.getTime() >= _ts.getTime()) return; // this isn't newer

      //var item = data.item;
      //if(selected != item.container) return; // match container to selected

      update();
    },
  });

  postal.subscribe({
    topic: "violation.created",
    callback: function (data, envelope) {
      //var _ts = new Date(data.generated || data.ts);

      //console.log(last + ", " + ts);

      //if(!!updated && updated.getTime() >= _ts.getTime()) return; // this isn't newer

      //var item = data.item;
      //if(selected != item.container) return; // match container to selected
      view();
      update();
    },
  });

  // trigger a refresh - all record types on a consistent refresh schedule
  //window.requestInterval(update, 1.5 * 60 * 1000);

  // what's the likelihood that a single record is updated while looking?
})(
  page,
  postal,
  ParkIQ.API,
  document.documentElement,
  document.querySelector("main[data-record]")
);

(function (root, Delegate) {
  var delegate = new Delegate(root);

  // close
  delegate.on("reset", "aside[data-record] form", function (e) {
    if (document.activeElement) document.activeElement.blur();
  });
})(document.documentElement, window.Delegate || window.domDelegate.Delegate);

export default self;
