(function(page, postal, api, root, container) {

	var updated = container.querySelector("time[data-valid]");
	var location;

	function valid(valid) {
		return Promise.resolve(valid)
		.then(function(valid) {
			if(!valid) return valid;
			updated.setAttribute("datetime", !!valid.toISOString ? valid.toISOString() : valid);
        	updated.dispatchEvent(new CustomEvent("update", { "bubbles": true }));
			return valid;
		})
		
	}

	function generated(results) {
		return Promise.resolve(results)
		.then(function(results) {
			return _.min(_.map(results, "generated"));
		})
	}

	function repeat(location) {
		var requested = new Date().toISOString();

		return api.fetch("GET", Promise.join(api.base(), location, function(base, location) {
            return base + "v1/locations/" + location + "/violations/exceptions?valid=" + requested;
        }), null, api.Auth.header(location));
	}
    function latest(location) {
		var requested = new Date().toISOString();

		return api.fetch("GET", Promise.join(api.base(), location, function(base, location){
			return base + "v1/locations/" + location + "/violations?issued=" + encodeURIComponent(dateFns.format(dateFns.subDays(new Date(), 7)) + "/" + dateFns.format(dateFns.addMinutes(new Date(), 5))) + "&files=true&sent=false";
		}), null, api.Auth.header(location));

		// return api.fetch("GET", Promise.join(api.base(), location, function(base, location) { 
        //         return base + "locations/" + location + "/violations?past=P7D&files=true&sent=true&ts=" + requested;
        // }), null, api.Auth.header(location));
	}

	function load(location) {
		return Promise.join(
			latest(location)
			.then(function(json) {
				if(!!postal) postal.publish({
                    topic   : "violations.latest",
                    data    : json
                });
				return json
			}),
			repeat(location)
			.then(function(json) {
				if(!!postal) postal.publish({
                    topic   : "violations.exceptions",
                    data    : json
		        });
				return json
			})
		);
	}

    function update() {

		if(!location) return;
        if(!root.matches("[data-records^='" + container.getAttribute("data-records") + "']")) return; // not on tab

		return load(location)
		.then(generated)
		.then(valid);

	}

	var polling = {
        timer:null,
        interval: 1.5 * 60 * 1000,
        wait: 30 * 1000,
    };
    _.extend(polling, {
        callback:_.throttle(update, polling.wait),
        start:function() {
            polling.timer = polling.timer || window.requestInterval(polling.callback, polling.interval);
        },
        stop:function() {
            if(!!polling.timer) window.clearRequestInterval(polling.timer);
            polling.timer = null;
        },
    });

	_.each([
		"violation.created",
		"violation.issued",
		//"violation.updated",
		"violation.deleted"
	], function(topic) {
		postal.subscribe({
			topic:topic,
			callback:update,
		});
	});
    

	function index(location) {
		update();
		polling.start();
	}
    

    var enter = function(ctx, next) {
        
        // show a specific violation
        root.setAttribute("data-records", container.getAttribute("data-records"));

        index(location = ctx.params.location);
        
    };
    
     var exit = function(ctx, next) {

        polling.stop();

        //view(); do we actually want to clear the views?
        
        next();
    }

    _.each([
        "/:location/violations",
    ], function(path) {
        page(path, enter);
        page.exit(path, exit);
    });

}(page, postal, ParkIQ.API, document.documentElement, document.querySelector("main[data-records='violations']")));

export default self;