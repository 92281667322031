import { router, state, end } from "./util/router";
import { api } from "./util/api";

// import Search from "./Search.svelte";
// import { items } from '@parkingboss/svelte-utils';



// // minimal ui
// if(location.search.indexOf("minimal=true") > 0) {

//     const searcher = new Search({
//         target: document.querySelector("nav.dynamic"),
//         props: {
//             items,
//             // recent: derived([recent, items], ([$recent, $state]) => {
//             //     return Object.values($recent || {}).sort((a, b) => b.ts - a.ts).map(item => {
//             //         return $state[item.value] || find($state, {
//             //             type: item.type,
//             //             key: item.value.toUpperCase()
//             //         }) || {
//             //             key: item.value.toUpperCase(),
//             //             display: item.value.toUpperCase(),
//             //             type: item.type,
//             //         };
//             //     }).filter(item => !!item);
//             // })
//         }
//     });
// }


function log(ctx, next) {
    //console.log("app.js router", ctx);

    if (next) next();
}

function view(viewname) {
    return function(ctx, next) {
        ctx.params.view = viewname;
        next();
    };
}

function normalizePlate(ctx, next) {
    var id = ctx.params.vehicle || ctx.params.id;
    if(!!id && typeof id == "string" && id.length < 10) {
        let normalized = id.toUpperCase().replace("O","0");
        if(normalized != id) return router.redirect(`/${ctx.params.property}/vehicles/${normalized}`);
    }
    next();
}

function mitigate(ctx, next) {
	if(ctx.params.property && ctx.params.property.length == 22) {
		return window.location.href = "https://my.communityboss.app/";
	}
	next();
}

function enforcement(ctx, next) {
	if(!ctx.params.view) ctx.params.view = "activity";
	if(ctx.params.view) ctx.params.view = "enforcement." + ctx.params.view;
	if(next) next();
}

//router.exit("*", unstate);

function logRecent(type) {
    return function(ctx, next) {

        //console.log("logRecent=", type, ctx);

        // recent.update($recent => {

        //     $recent[`${type}/${ctx.params.id}`] = {
        //         type,
        //         value: ctx.params.id,
        //         ts: Date.now()
        //     };

        //     return $recent;
        // });


        next();
    }
}


router("/:property/*", log, state);

router("/:property/vehicles/:id", normalizePlate, log, logRecent("vehicle"), state);
router("/:property/spaces/:id", log, logRecent("space"), state);
router("/:property/tenants/:id", log, logRecent("tenant"), state);
router("/:property/media/:id", log, logRecent("media"), state);

router("/:property/scan", view("scan"), state, end);

router("/:property/logout", function(ctx, next) {
    api.logOut(true);
    if (next) next();
});

export default window;