import { readable } from "svelte/store";

var mql = window.matchMedia("only screen and (max-width: 979px)");
export const smscreen = readable(mql.matches, set => {
    set(mql.matches);
    const f = (e) => set(e.matches);
    mql.addEventListener ? mql.addEventListener("change", f) : mql.addListener(f);
    return () => mql.addEventListener ? mql.removeEventListener("change", f) : mql.removeListener(f);
});

export const pagevisible = readable(!document.hidden, set => {
    set(!document.hidden);
    const f = () => set(!document.hidden);
    document.addEventListener("visibilitychange", f);
    return () => document.removeEventListener("visibilitychange", f);
});

export const online = readable(navigator.onLine, set => {
    set(navigator.onLine);
    const f = () => set(navigator.onLine);
    window.addEventListener("online", f);
    window.addEventListener("offline", f);

    return () => {
        window.removeEventListener("online", f);
        window.removeEventListener("offline", f);
    };

});

export const touch = readable(!document.hidden, set => {
    set(false);
    const events = [
        [ "touchstart", () => set(true) ],
        [ "touchend", () => set(false) ],
    ];
    const o = { passive: true };

    for(const [e, f] of events) document.addEventListener(e, f, o);

    return () => {
        for(const [e, f] of events) document.removeEventListener(e, f, o);
    } 
});

export const embedded = readable(top != self);

export const standalone = readable(!!navigator.standalone);