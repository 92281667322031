import { format } from "date-fns";
import { utcToZonedTime, format as formatTZ } from "date-fns-tz";

export function toZoneISOString(local, tz) {
    //console.log(local, tz);
    //if(!tz) return local.toISOString();
    return formatTZ(local, "yyyy-MM-dd'T'HH:mm:ssxxx", { timeZone: tz });
}

export function timeLocal(store, tz) {
    return derived(store, value => utcToZonedTime(value, tz));
}

export function utcToZoned(value, timezone) {
    return utcToZonedTime(value, timezone);
}

export const display = function(date, relativeTo) {
    return format(date, "EE MMM d yyyy h:mm a");
}

export const iso8601Offset = function(date) {
    return format(date, "yyyy-MM-dd'T'HH:mm:ssxxx");
}
export const iso8601 = function(date) {
    if(!date) return "";
    return date.toISOString();
}

export const stringToInterval = function(interval, parser) {
    if(typeof(interval) !== "string") return interval;
    const [ start, end ] = interval.split("/").map(d => !!parser ? parser(d) : new Date(d));
    return { start, end };
}

export const stringToDates = function(interval, parser) {
    if(typeof(interval) !== "string") return interval;
    return interval.split("/").map(d => !!parser ? parser(d) : new Date(d));
}

function minMaxDate(input, f) {
    if(!input) return input;
    if(input.toISOString) return input;
    if(typeof input == "string") return new Date(f(...stringToDates(input)))
    return null;
}

export const maxDate = (input) => minMaxDate(input, Math.max);

export const minDate = (input) => minMaxDate(input, Math.min);

export const toInterval = function(value) {

    if(!value) return null;

    // single date
    if(value.toISOString) return {
        start:value,
        end:value,
    };

    // array of dates/num/string
    if(Array.isArray(value)) return {
        start: value[0] && new Date(value[0]),
        end: value[1] && new Date(value[1]),
    };

    return stringToInterval(value);

}