<script>
    import { invoke, debounce, isString } from "lodash-es";
    import { fuzzy, search as fuzzySearch } from "fast-fuzzy";
    import { autocreate as autocreateVehicle } from "../util/vehicle";

    import { state } from "../util/propertystores";
    import Record from "./Record.svelte";

    export let query;

    export let types = {};

    $: searchable = Object.assign({
        "unit":true,
        "tenant":false,
        "vehicle":true,
        "media":true,
        "space":true,
    }, types || {});

    $:showResults = query;

    const comparer = !!window.Intl && !!window.Intl.Collator ? new Intl.Collator(undefined, { numeric: true, sensitivity: 'base', caseFirst: false }).compare : (a, b) => a < b ? -1 : a > b ? 1 : 0;
    const itemComparer = (a, b) => comparer(a.display, b.display);    

    
    // const identify = {
    //     "vehicle":item => item.key,
    //     "tenant":item => item.key,
    //     "unit":item => item.key,
    // }

    var autocreate = {
        "vehicle":{
            autocreate:autocreateVehicle,
        },
    };

    function isExactMatch(item, query) {
        return item.key == query;
    }

    var index = {
        // added: {},
        // items: {},
        // //searcher
        // add:function(itemOrItems) {
        //     if(!itemOrItems) return false;

        //     //console.log("indexing", itemOrItems)

        //     if(isArrayLike(itemOrItems)) {
        //         each(itemOrItems, index.add); // run through eeach
        //         return true;
        //     } 

        //     if(!itemOrItems.key || !itemOrItems.type) return false;

        //     const item = itemOrItems;

        //     //if(!!find(index.items, itemOrItems)) return true;
            
        //     // use a consistent cache by key to prevent duplicates
        //     index.items[item.type + "_" + item.key] = index.added[item.type + "_" + item.key] = defaults(item, {
        //         display:item.key,
        //     });

        //     if(!!item.id) index.items[item.type + "_" + item.id] = item;

        //     return true;

        // },
        search:function(query, items) {

            if(!isString(query)) return null;

            var original = query;

            //const results = [];

            query = (invoke(query || "", "toUpperCase") || "").replace(/[^A-Z0-9]/gi, "");

            if(!query) return {
                query:original,
                items:[],
            }

            // items
            const searchItems = Object.values(items).reduce((result, item) => {
                const type = item && (item._type || item.type);
                if(!type || !searchable[type]) return result;
                if(result[item.id]) return result;
                result.ids[item.id] = item;
                if(autocreate[item.type]) result.keys[item.type + '/' + item.key] = item;
                return result;
            }, {
                ids:{},
                keys:{},
            });
            var autocreated = Object.values(autocreate).reduce((results, type) => {

                const autocreated = type.autocreate && type.autocreate(query);

                if(autocreated && !searchItems.keys[autocreated.type + '/' + autocreated.key]) results.push(autocreated);

                return results;
            }, []);

            const results = fuzzySearch(query, Object.values(searchItems.ids).sort(itemComparer), {
                threshold:0.8,
                keySelector:(item) => [item.display, item.key].filter(i => !!i),
                returnMatchData: true
            });

            return {
                query:original,
                items:results.reduce((result, item) => {
                    if(isExactMatch(item.item, query)) result.push(item.item);
                    //if(item.score >= 1) result.push(item.item);
                    return result;
                }, []).concat(
                        autocreated, 
                        results.reduce((result, item) => {
                            if(!isExactMatch(item.item, query)) result.push(item.item);
                            //if(item.score < 1) result.push(item.item);
                            return result;
                    }, [])
                ),
            };

            return {
                query:original,
                //original: original,
                items:results, //.reverse(),
            }
        }
    };

    //$: if(items && $items) index.add(Object.values($items).filter(i => !!bases[i._type || i.type]));

    //$: $items && console.log("items=", $items);

    $: results = (query ? index.search(query, $state).items : []) || [];

</script>

{#if showResults && results.length}
<button type="button" class="close" on:click={e => (query = null)}></button>
<ul class="lookup results">
    {#each results as item}
    <li on:click={e => (query = null)}>
        <Record {item} />
    </li>
    {/each}
</ul>
{/if}