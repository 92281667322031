<script context="module">
  import identity from "lodash-es/identity";
  import { href } from "../util/router";
</script>

<script>
  export let url = true;
  export let item;
</script>

{#if item}
  {#if url}
    <a href={true === url ? href(item) : url}>
      <data
        class={[
          "id",
          item.type,
          item._type,
          item.format,
          item.color?.toLowerCase().replace(" ", "-"),
        ]
          .filter(identity)
          .join(" ")}
        value={item.id || item.key}
        >{item.display}{#if item.color}<data
            class="color"
            value={item.color.toLowerCase()}
          />{/if}{#each ["capacity:charging", "capacity:disabled", "capacity:lift", "size"].filter((key) => !!item[key]) as key}<data
            class={key.replace(":", "-")}
            value={item[key]}
          />{/each}</data
      >
    </a>
  {:else}
    <data
      class={[
        "id",
        item.type,
        item._type,
        item.format,
        item.color?.toLowerCase().replace(" ", "-"),
      ]
        .filter(identity)
        .join(" ")}
      value={item.id || item.key}
      >{item.display}{#if item.color}<data
          class="color"
          value={item.color.toLowerCase()}
        />{/if}{#each ["capacity:charging", "capacity:disabled", "capacity:lift", "size"].filter((key) => !!item[key]) as key}<data
          class={key.replace(":", "-")}
          value={item[key]}
        />{/each}</data
    >
  {/if}
{/if}
