(function(postal, root) {

    function location(location) {

		
		if(!location) return;
		
		document.title = location.name + " - Parking Boss";

		//console.log("update location", location);
        
		_.each(root.querySelectorAll("body > header h1"), function(elem) {
			elem.textContent = location.name;
			//elem.setAttribute("title", location.name);
		});
		
		var vehiclePermits = !!_.get(location, "vehicles.permits");
		var attendant = vehiclePermits && !!location.attendant; // attendant requires vehicle permits for ui
		root.classList[attendant ? "add" : "remove"]("attendant");

		_.each(document.querySelectorAll("a[href='permits/vehicles'],main[data-records='permits/vehicles'] > header h1"), function(elem) {
			//elem.setAttribute("title", location.attendant.title); 
			elem.textContent = attendant ? location.attendant.title : "";
		});
		
		root.classList[!!_.get(location, "vehicles") ? "add" : "remove"]("vehicles");
		root.classList[vehiclePermits ? "add" : "remove"]("vehicle-permits");

		if(!!location.tenants) {
			root.classList.add("tenants");
			if(!!location.tenants.type) root.classList.add(location.tenants.type);
		} else root.classList.remove("tenants");


		if(!!location.media && !!location.media.permits) {
			root.classList.add("media");
			if(!!location.media.format) root.classList.add(location.media.format);
		} else root.classList.remove("media");
		root.classList[!!_.get(location, "media.permits") ? "add" : "remove"]("media-permits");
		
		root.classList[!!_.get(location, "spaces") ? "add" : "remove"]("spaces");
		root.classList[!!_.get(location, "spaces.permits") ? "add" : "remove"]("space-permits");

		root.classList[!!_.get(location, "users.fieldagents") ? "add" : "remove"]("fieldagents");
		root.classList[!!_.get(location, "tenants.donotpermit") ? "add" : "remove"]("tenants-donotpermit");

		var exceptions = _.get(location, "violations.thresholds");
		root.classList[!!exceptions ? "add" : "remove"]("violation-thresholds");
		_.each(root.querySelectorAll("a[data-records='violations/exceptions'], a[href='violations/exceptions']"), function(elem) {
			//if(!exceptions || exceptions === true) elem.removeAttribute("title");
			//else elem.setAttribute("title", exceptions); 
			if(!exceptions || exceptions === true) return;
			if(!elem.textContent) elem.textContent = exceptions;
			elem.setAttribute("title", exceptions);
		});

		if(!!_.get(location, "violations.reasons")) {

			var html = _.reduce(_.get(location, "violations.reasons.items") || _.get(location, "violations.reasons", []), function(html, item) {
				return html + '<option class="custom">' + item + '</option>';
			}, "");

			//console.log("reasons", _.get(location, "violations.reasons.items"), html);

            _.each(root.querySelectorAll("optgroup.custom-violations"), function(elem) {
                elem.outerHTML = html;
            });

        }

		document.documentElement.dispatchEvent(new CustomEvent("ready", { bubbles: true }));

	};
    
    postal.subscribe({
		topic:"location.updated",
		callback : function(data, envelope) {
		    
		    // save new on update
		    location(data.item);
            
            // save to storage for first run
		    
	    },
	});
    
    // rehydrate from storage on first run?
    
}(postal, document.documentElement));

export default self;