<script>
    
    import { debounce, isString } from "lodash-es";
    import { propertyId } from "../util/propertystores";
    import { touch } from "../util/behaviorstores";

    import { route } from "../util/router";
    //import { param, params } from "../util/params";

    export let query;
    export let scan = false;

    //const lookup = param("lookup");

    //let scan = false;

    let input = null;
    let form = null;

    var types = {
        "vehicle":{
            autocreate:function(key) {
                if(!key || !isString(key)) return null;
                key = key.replace("O", "0");
                if(key.length <= 2) return null;
                if(key.length >= 10) return null;
                return {
                    type:"vehicle",
                    key:key,
                    display:key,
                };
            },
        },
    };

    // don't let this change too fast
    const search = debounce(function(e) {

        query = (e && e.target && e.target.value);

        // params.update(values => {
        //     value = values["lookup"] = (e && e.target && e.target.value);
        //     return values;
        // });

        // if(e && e.target) //query("lookup", e.target.value);
        // else params
        if(query) scan = false;
        // showResults = !!query;
        // if(showResults) scan = false;
    }, 100, {
        leading:true,
        trailing:true,
    });

    function blur() {
        if(input) input.blur();
        window.blur();
    }

    async function clear() {
        //scan = false;
        form.reset();
        search();
        //blur();
    }

    function onInputBlur(e) {
        console.log("oninputblur=", e, e.target.value, "touch=", $touch);
        if(!e.target.value) clear();
    }

    function submit(e) {
        e.preventDefault();
        if(!input || !input.value) return;
        var vehicle = types.vehicle.autocreate(input.value);
        if(!vehicle) return;
        route(`/${$propertyId}/vehicles/${vehicle.key}`);
        blur();
        clear();

    }

    // if in scan mode clear out search
    $: if(scan) clear();

</script>

<form class="lookup" bind:this={form} on:submit={submit}>
    <input bind:this={input} value="{query||""}" type="search" placeholder="Lookup" required on:input={search} on:focus={search} on:blur={onInputBlur} autocomplete="off" autocapitalize="off" autocorrect="off" />
    <button type="button" on:click={e => (scan = !scan)} class:active={scan}></button>
</form>