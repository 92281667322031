(function (root, api, page, Delegate, postal) {
  var container = root.querySelector(
    "section[data-records='violations/latest']"
  );
  var list = container.querySelector("ul[data-records='violations/latest']");
  var updated = container.querySelector("time[data-valid]");
  var location;
  var loaded;

  var views = {
    index: function (items, valid) {
      views.list(list, items);

      if (!!updated) {
        updated.setAttribute(
          "datetime",
          !!valid.toISOString ? valid.toISOString() : valid
        );
        updated.dispatchEvent(new CustomEvent("update", { bubbles: true }));
      }
    },
    list: function (list, items) {
      list.classList.remove("empty");

      if (!items) {
        while (!!list.firstChild) list.removeChild(list.firstChild);
        return;
      }

      list.innerHTML = _.reduce(
        items,
        function (html, item) {
          return html + ParkIQ.API.Templates[item.__type](item);
        },
        ""
      );
      if (!list.hasChildNodes()) list.classList.add("empty");
    },
  };

  function view(result) {
    views.index(result.violations, result.generated);
    // set the info

    //console.log(result.violations);

    if (!!result.duration) {
      var d = moment.duration(result.duration);
      var days = Math.round(d.asDays());
      _.each(container.querySelectorAll("header p"), function (elem) {
        elem.textContent =
          "Recorded in the last " + (1 === days ? "day" : days + " days");
      });
    }
    _.each(root.querySelectorAll("a[href='violations/latest']"), function (a) {
      a.setAttribute(
        "data-records-count",
        _.get(result, "violations.length", 0)
      );
    });

    _.each(_.get(result, "violations", []), function (item) {
      if (!item || !item.sent) return;
      postal.publish({
        topic: item.__type + ".sent.updated",
        data: {
          generated: result.generated,
          item: item.id,
          items: _.get(item, "sent.items", item.sent),
        },
      });
    });

    return result;
  }

  function process(json) {
    var users = _.get(json, "users.items", json.users);
    var vehicles = _.get(json, "vehicles.items");
    var tenants = _.get(json, "tenants.items");
    var spaces = _.get(json, "spaces.items");
    var files = _.get(json, "files.items");

    return {
      requested: json.requested,
      generated: json.generated,
      viewpoint: json.viewpoint,
      duration: json.duration,
      interval: json.interval,
      location: _.get(json, [
        "locations",
        "items",
        _.get(json, "locations.item"),
      ]),
      violations: _.chain(json)
        .get("violations.items")
        .map(function (item) {
          item.__type = "violation";

          api.Items.user(item.issued, users);
          api.Items.notes(item, json.notes);
          api.Items.files(item, files);
          api.Items.sent(item, json.sent);

          api.Items.vehicle(item, vehicles);
          api.Items.tenant(item, tenants);
          //if(!!item.tenant) item.tenant.type = _.get(json, "tenants.type", item.tenant.type);
          api.Items.space(item, spaces);

          item = api.Items.normalize(item);

          postal.publish({
            topic: item.__type + ".updated",
            data: {
              generated: json.generated,
              item: item,
            },
          });

          return item;
        })
        .orderBy(["issued.utc"], ["desc"])
        .value(),
    };
  }

  if (!!postal)
    postal.subscribe({
      //channel  : "location",
      topic: "violations.latest",
      callback: function (data, envelope) {
        if (!data) return;
        // save new on update
        //console.log(data);
        var processed = process(data);
        if (!processed) return;
        //console.log(processed);

        location = _.get(processed, "location.id", location);

        if (!!loaded && loaded.generated > processed.generated) return;
        view((loaded = processed));
      },
    });

  function get(location) {
    var requested = new Date().toISOString();

    return api.fetch(
      "GET",
      Promise.join(api.base(), location, function (base, location) {
        return (
          base +
          "v1/locations/" +
          location +
          "/violations?issued=" +
          encodeURIComponent(
            dateFns.format(dateFns.subDays(new Date(), 7)) +
              "/" +
              dateFns.format(dateFns.addMinutes(new Date(), 5))
          ) +
          "&files=true&sent=false"
        );

        //return base + "locations/" + location + "/violations?past=P7D&files=true&sent=true&ts=" + requested;
      }),
      null,
      api.Auth.header(location)
    );
  }

  function load(location) {
    return get(location)
      .then(process)
      .then(function (result) {
        return (loaded = result);
      });
  }

  var update = _.debounce(
    function () {
      //if(!!id) location = id;
      if (!location) return;
      if (!root.matches("[data-records^='violations']")) return; // not on tab
      load(location).then(view);
    },
    30 * 1000,
    {
      leading: true,
    }
  );

  // trigger updates
  /*
	_.each([
		"violation.created",
		"violation.issued",
		//"violation.updated",
		"violation.deleted"
	], function(topic) {
		postal.subscribe({
			topic:topic,
			callback:update,
		});
	});
	*/

  var controller = {
    index: function (location) {
      update();
    },
  };

  var keys = {
    records: null,
  };

  function remove(map) {
    _.each(map, function (value, key) {
      document.documentElement.removeAttribute("data-" + key);
    });
  }
  function set(map) {
    _.each(map, function (value, key) {
      if (!!value) document.documentElement.setAttribute("data-" + key, value);
      else document.documentElement.removeAttribute("data-" + key);
    });
  }
  function unset(ctx, next) {
    remove(keys);
    next();
  }

  page(
    "/:location/violations/latest",
    function (ctx, next) {
      set(
        _.extend(keys, {
          records: "violations/latest",
        })
      );
      next();
    },
    function (ctx, next) {
      controller["index"]((location = ctx.params.location));
      //next();
    }
  );

  page.exit("/:location/violations/latest", unset);
})(
  document.documentElement,
  ParkIQ.API,
  page,
  window.Delegate || window.domDelegate.Delegate,
  postal
);

export default self;
