(function(root, Delegate) {

	var nav = root.querySelector("nav.app");

	if(!nav) return; // nothing to do

	var activateUI = nav.parentNode.querySelector("button.nav.app.activate");
	if(!activateUI) {
		activateUI = document.createElement("button");
		activateUI.setAttribute("type", "button");
		activateUI.classList.add("app", "nav", "activate");
		nav.parentNode.insertBefore(activateUI, nav);
	}
    var deactivateUI = nav.querySelector("button.nav.app.deactivate");
	if(!deactivateUI) {
		deactivateUI = document.createElement("button");
		deactivateUI.setAttribute("type", "button");
		deactivateUI.classList.add("app", "nav", "deactivate");
		nav.appendChild(deactivateUI);
	}

	function isActive() {
		return root.classList.contains("nav-active");
	}

	function deactivate() {
		document.documentElement.classList.remove("nav-active");
	}

	function activate() {
		document.documentElement.classList.add("nav-active");
	}

    activateUI.addEventListener("click", function(e) {
		activate();
	});
	deactivateUI.addEventListener("click", function(e) {
		deactivate();
	});

	var delegate = new Delegate(nav);
    
    delegate.on("click", "a", function() {
		deactivate();
	});

	

	// click outside
	root.addEventListener("click", function(e) {
		if(!isActive()) return; // don't continue if not nav active
		if(activateUI.contains(e.target) || nav.contains(e.target)) return; // clicked inside
		deactivate();
	});
    
}(document.documentElement, window.Delegate || window.domDelegate.Delegate));

export default self;