
<script context="module">
    const scanMode = writable("media"); // maybe write to storage?
</script>
<script>

import { propertyId, property } from "./util/propertystores";
import { writable } from "@parkingboss/svelte-storage";
import VehicleScanner from "./components/VehicleScanner.svelte";
import { router } from "./util/router";
import MediaScanner from "./components/MediaScanner.svelte";
import { tick } from "svelte";
import Search from "./components/Search.svelte";
import SearchResults from "./components/SearchResults.svelte";


    let query = "";
    let scan = false;


    async function setScanMode(mode) {
        //console.log("setting scan mode = ", mode);
        await tick();
        scanMode.set(mode);
    }

    function blur() {
        //if(input) input.blur();
        window.blur();
    }

    async function clear() {
        scan = false;
        query = "";
        //blur();
    }

    function onInputBlur(e) {
        // console.log("oninputblur=", e, e.target.value, "touch=", $touch);
        // if(!e.target.value) clear();
    }

    //$: if(scan) showResults = false;


    function submit(e) {
        e.preventDefault();

        if(e.detail && e.detail.type == "vehicle") {
            let vehicle = e.detail;
            blur();
            clear();
            return router(`/${$propertyId}/vehicles/${vehicle.key}`);
        }

        if(e.detail && e.detail.type == "media") {
            let media = e.detail;
            console.log("have media=", media);
            blur();
            clear();
            return router(`/${$propertyId}/media/${media.id}`);
        }

    }

</script>

<Search bind:query={query} bind:scan={scan} />

{#if scan}
<aside class="scanner">
    <ul on:change={e => (e.target.checked && scanMode.set(e.target.value))}>
        <li><input id="scan-media" type="radio" name="scan" value="media" bind:group={$scanMode} /><label for="scan-media">Smart Decal</label></li>
        <li><input id="scan-vehicle" type="radio" name="scan" value="vehicle" bind:group={$scanMode} /><label for="scan-vehicle">Plate/Tag</label></li>
    </ul>
{#if scan && $scanMode == "vehicle" && $property}
    <VehicleScanner property={$property} on:vehicle={submit} />
    <button type="button" value="media" on:click={e => setScanMode("media")}>Switch to scan Smart Decal</button>
{:else if scan && $scanMode == "media" && $property}
    <MediaScanner property={$property} on:media={submit} />
    <button type="button" value="vehicle" on:click={e => setScanMode("vehicle")}>Switch to scan Plate/Tag</button>
{/if}
</aside>
{/if}
{#if scan}
    <button type="button" class="close" on:click={clear}></button>
{/if}
<SearchResults bind:query={query} types={
    {
        "unit":$property?.enforcement?.units,
    }
} />