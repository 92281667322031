(function(page, api, root, postal) {
    
    var user;
    
    postal.subscribe({
		topic:"user.updated",
		callback : function(data, envelope) {

            //console.log("user.updated", data.item);
		    
		    // save new on update
		    user = data.item;
            
            if(!user) return;
		
            root.classList.add("user");
            if(user.admin) root.classList.add("system-admin");
            else root.classList.remove("system-admin");

            //var logout = nav.querySelector("a[href='logout']");
            // if(!!user.url) 
            _.each(root.querySelectorAll("a[rel='profile']"), function(a) {
                a.setAttribute("href", user.url || ("https://my.parkingboss.com/users/" + user.id));
            });

            //console.log(data);

            //var valid = data.generated;

            // _.each(root.querySelectorAll("section[data-records='user/locations'] time[data-valid]"), function(elem) {
            //     elem.setAttribute("datetime", !!valid.toISOString ? valid.toISOString() : valid);
        	//     elem.dispatchEvent(new CustomEvent("update", { "bubbles": true }));
            // });
            
            //console.log(window.rg4js);
            if(!!window.rg4js) rg4js('setUser', {
                identifier: user.id,
                isAnonymous: false,
                email: user.email,
                fullName: user.name,
            });

            if(!!window.appInsights) appInsights.setAuthenticatedUserContext(user.email);
            
            if(!!window.HS && !!HS.beacon) {
                HS.beacon.ready(function() {
                    HS.beacon.identify({
                        name: user.name,
                        email: user.email,
                    });
                });
            }

            // intercom
            if(!!window.Intercom) {
                window.Intercom("update", {
                    name: user.name, // Full name
                    email: user.email, // Email address
                    created_at: Date.parse(_.get(user, "created.utc", user.created)) / 1000, // Signup date as a Unix timestamp
                });
            }
		    
	    },
	});

    var unreadSetup = _.once(function() {
        Intercom('onUnreadCountChange', function(unreadCount) {
            _.each(root.querySelectorAll("a[href='mailto:help@parkingboss.com'][data-unread]"), function(elem) {
                console.log(elem, unreadCount);
                elem.setAttribute("data-unread", unreadCount + "");
            });
        });
    });
    page("*", function(ctx, next) {
        if(!!window.Intercom) window.Intercom("update"); // update url
        if(!!window.Intercom) unreadSetup();
        next();
    });
    
    document.addEventListener("unauthorized", function(e) {
        root.classList.add("unauth");
        // reshow intercom
        if(!!window.Intercom) window.Intercom("update", {
            hide_default_launcher:false
        });
    });


    function login(location) {

        api.Auth.remove(location);
        window.location.href = document.querySelector("link[rel~='authenticate']:not([media])").href + "login?l=" + location + "&v=" + (new Date()).toISOString();
    }
    
    
    
    var logout = function(location) {

        //console.log("logout", user);

        api.Auth.remove(location);

        if(!!window.Intercom) window.Intercom("shutdown"); // stop session

        // need to send to login/logout
        var id = _.get(user, "id", user);
        if(!!id) window.location.href = document.querySelector("link[rel~='authenticate']:not([media])").href + "users/" + id + "/logout?l=" + location + "&v=" + (new Date()).toISOString();
        else window.location.href = document.querySelector("link[rel~='authenticate']:not([media])").href + "user/logout?l=" + location + "&v=" + (new Date()).toISOString();

    };

    page("/:location/login", function(ctx, next) {
	
        login(ctx.params.location);
        next();
        
    }, function(ctx) {
        //root.dispatchEvent(new CustomEvent("unauthorized", { bubbles: true }));
    });
    
    page("/:location/logout", function(ctx, next) {
        
        logout(ctx.params.location);
        next();
        
    }, function(ctx) {
        //root.dispatchEvent(new CustomEvent("unauthorized", { bubbles: true }));
    });
    
}(page, ParkIQ.API, document.documentElement, postal));

export default self;