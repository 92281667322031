(function(root, Delegate){
    
    var delegate = new Delegate(root);

    delegate.on("click", "main nav[data-records] a", function(e) {
       e.preventDefault();
       this.closest("nav[data-records]").setAttribute("data-records", this.getAttribute("href") || this.getAttribute("data-records")); 
    });
    
}(document.documentElement, window.Delegate || window.domDelegate.Delegate));

export default self;