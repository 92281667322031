(function (page, postal, api, root, section) {
  var get = Promise.method(function (location, donotpermit, sent) {
    var requested = new Date().toISOString();

    return Promise.join(api.base(), location, function (base, location) {
      return Promise.join(
        api
          .fetch(
            "GET",
            base +
              "v1/attendants/" +
              location +
              "/permits/temporary?valid=" +
              encodeURIComponent(
                dateFns.format(dateFns.subHours(new Date(), 6)) +
                  "/" +
                  dateFns.format(dateFns.addHours(new Date(), 6))
              ) +
              "&contact=" +
              !(api.contact === false) +
              "&sent=" +
              sent,
            null,
            api.Auth.header(location)
          )
          .then(function (json) {
            json.requested = requested;

            var location = _.get(
              json,
              ["locations", "items", _.get(json, "locations.item")],
              json.location
            );
            var permits = _.get(json, "permits.items");
            var users = _.get(json, "users.items");
            var vehicles = _.get(json, "vehicles.items");
            var tenants = _.get(json, "tenants.items");
            var spaces = _.get(json, "spaces.items");

            var tenantType = _.get(json, "tenants.type");

            permits = _.map(permits, function (item) {
              item.__type = "permit";

              api.Items.vehicle(item, vehicles);
              api.Items.tenant(item, tenants);
              api.Items.space(item, spaces);

              api.Items.user(item.issued, users);
              api.Items.user(item.updated, users);
              api.Items.notes(item, json.notes);
              if (!!sent) api.Items.sent(item, json.sent);

              item.contact = _.get(json, ["contacts", "items", item.id]);

              if (!!item.tenant)
                item.tenant.type = item.tenant.type || tenantType;

              if (!!item.attendant)
                item.attendant = _.get(
                  json,
                  ["attendants", "items", item.attendant],
                  item.attendant
                );

              return api.Permits.normalize(item);
            });

            if (!!location)
              postal.publish({
                topic: "location.updated",
                data: {
                  requested: json.requested,
                  generated: json.generated,
                  item: location,
                },
              });

            postal.publish({
              topic: "permits.vehicles.updated",
              data: {
                requested: json.requested,
                generated: json.generated,
                items: permits,
              },
            });

            postal.publish({
              topic: "tenants.items.updated",
              data: {
                requested: json.requested,
                generated: json.generated,
                items: _.map(tenants),
                predefined: _.get(json, "tenants.predefined"),
              },
            });

            postal.publish({
              topic: "vehicles.items.updated",
              data: {
                requested: json.requested,
                generated: json.generated,
                items: _.map(vehicles),
              },
            });

            postal.publish({
              topic: "spaces.items.updated",
              data: {
                requested: json.requested,
                generated: json.generated,
                items: _.map(spaces),
                predefined: _.get(json, "spaces.predefined"),
              },
            });

            _.each(permits, function (item) {
              postal.publish({
                topic: "permit.updated",
                data: {
                  requested: json.requested,
                  generated: json.generated,
                  item: item,
                },
              });
            });

            return json;
          }),
        !donotpermit
          ? {}
          : api
              .fetch(
                "GET",
                base +
                  "v1/attendants/" +
                  location +
                  "/permittables/negative?valid=" +
                  encodeURIComponent(
                    dateFns.format(dateFns.addHours(new Date(), 6))
                  ),
                null,
                api.Auth.header(location)
              )
              .then(function (json) {
                json.requested = requested;

                var location = _.get(
                  json,
                  ["locations", "items", _.get(json, "locations.item")],
                  json.location
                );
                var users = _.get(json, "users.items");
                var vehicles = _.get(json, "vehicles.items");
                var tenants = _.get(json, "tenants.items");
                var spaces = _.get(json, "spaces.items");

                var tenantType = _.get(json, "tenants.type");

                var donotpermit = _.get(json, "permittables.items");
                if (!!donotpermit)
                  donotpermit = _.map(donotpermit, function (item) {
                    item.__type = "permittable";

                    api.Items.user(item.issued, users);
                    api.Items.notes(item, json.notes);

                    if (!!item.scope)
                      item.attendant = _.get(json, [
                        "attendants",
                        "items",
                        item.scope,
                      ]);

                    //if(!!item.issued && !!item.issued.user && !item.issued.user.id) item.issued.user = users[item.issued.user];
                    if (!!vehicles) item.vehicle = vehicles[item.container];
                    if (!!tenants) item.tenant = tenants[item.container];
                    if (!!item.tenant)
                      item.tenant.type = item.tenant.type || tenantType;
                    //item.notes = item.notes || _.map(data.notes[item.id]);

                    return api.Items.normalize(item);
                  });

                if (!!location)
                  postal.publish({
                    topic: "location.updated",
                    data: {
                      requested: json.requested,
                      generated: json.generated,
                      item: location,
                    },
                  });

                postal.publish({
                  topic: "tenants.items.updated",
                  data: {
                    requested: json.requested,
                    generated: json.generated,
                    items: _.map(tenants),
                    predefined: _.get(json, "tenants.predefined"),
                  },
                });

                postal.publish({
                  topic: "vehicles.items.updated",
                  data: {
                    requested: json.requested,
                    generated: json.generated,
                    items: _.map(vehicles),
                  },
                });

                postal.publish({
                  topic: "spaces.items.updated",
                  data: {
                    requested: json.requested,
                    generated: json.generated,
                    items: _.map(spaces),
                    predefined: _.get(json, "spaces.predefined"),
                  },
                });

                postal.publish({
                  topic: "permittables.donotpermit.updated",
                  data: {
                    requested: json.requested,
                    generated: json.generated,
                    items: donotpermit,
                  },
                });

                _.each(donotpermit, function (item) {
                  postal.publish({
                    topic: "permittable.updated",
                    data: {
                      requested: json.requested,
                      generated: json.generated,
                      item: item,
                    },
                  });
                });

                return json;
              }),

        function (permits, permittables) {
          return true; // loaded;
        }
      );
    });

    // var url = "locations/" + location + "/permits/vehicles?past=6:00&future=6:00&donotpermit=" + (donotpermit === true) + "&ts=" + requested + "&contact=" + !!api.contact + "&sent=" + !!api.sent;

    // return Promise.join(api.base(), location, function(base, location) {
    // 	return api.fetch("GET", base + url, null, api.Auth.header(location));
    // })
    // .then(function(json) {

    //     json.requested = requested;

    //     var location = _.get(json, ["locations", "items", _.get(json, "locations.item")], json.location);
    //     var permits = _.get(json, "permits.items");
    //     var types = _.get(json, "media.types.items") || _.get(json, "media.types");
    //     var users = _.get(json, "users.items");
    //     var vehicles = _.get(json, "vehicles.items");
    //     var tenants = _.get(json, "tenants.items");
    //     var spaces = _.get(json, "spaces.items");

    //     var tenantType = _.get(json, "tenants.type");

    //     permits = _.map(permits, function(item) {

    //         item.__type = "permit";

    //         api.Items.vehicle(item, vehicles);
    //         api.Items.tenant(item, tenants);
    //         api.Items.space(item, spaces);

    //         api.Items.user(item.issued, users);
    //         api.Items.user(item.updated, users);
    //         api.Items.notes(item, json.notes);
    //         api.Items.sent(item, json.sent);

    //         item.contact = _.get(json, ["contacts", "items", item.id]);

    //         if(!!item.tenant) item.tenant.type = item.tenant.type || tenantType;

    //         if(!!item.media && item.media.type && !!types && _.isString(item.media.type)) item.media.type = types[item.media.type];

    //         if(!!item.attendant) item.attendant = _.get(json, [ "attendants", "items", item.attendant ], item.attendant);

    //         return api.Permits.normalize(item);

    //     });

    //     var donotpermit = _.get(json, "donotpermit.items");
    //     if(!!donotpermit) donotpermit = _.map(donotpermit, function(item) {
    //         item.__type = "permittable";

    //         api.Items.user(item.issued, users);
    //         api.Items.notes(item, json.notes);

    //         if(!!item.scope) item.attendant = _.get(json, [ "attendants", "items", item.scope]);

    //         //if(!!item.issued && !!item.issued.user && !item.issued.user.id) item.issued.user = users[item.issued.user];
    //         if(!!vehicles) item.vehicle = vehicles[item.container];
    //         if(!!tenants) item.tenant = tenants[item.container];
    //         if(!!item.tenant) item.tenant.type = item.tenant.type || tenantType;
    //         //item.notes = item.notes || _.map(data.notes[item.id]);

    //         return api.Items.normalize(item);
    //     });

    //     if(!!location) postal.publish({
    //         topic   : "location.updated",
    //         data    : {
    //             requested:json.requested,
    //             generated: json.generated,
    //             item: location,
    //         }
    //     });

    //     postal.publish({
    //         topic   : "permits.vehicles.updated",
    //         data    : {
    //             requested:json.requested,
    //             generated: json.generated,
    //             items: permits,
    //         }
    //     });

    //     _.each(permits, function(item) {
    //         postal.publish({
    //             topic   : "permit.updated",
    //             data    : {
    //                 requested:json.requested,
    //                 generated: json.generated,
    //                 item: item,
    //             }
    //         });
    //     });

    //     if(!!donotpermit) {
    //         postal.publish({
    //             topic   : "permittables.donotpermit.updated",
    //             data    : {
    //                 requested:json.requested,
    //                 generated: json.generated,
    //                 items: donotpermit,
    //             }
    //         });

    //         _.each(donotpermit, function(item) {
    //             postal.publish({
    //                 topic   : "permittable.updated",
    //                 data    : {
    //                     requested: json.requested,
    //                     generated: json.generated,
    //                     item: item,
    //                 }
    //             });
    //         });
    //     }

    //     postal.publish({
    //         topic   : "tenants.items.updated",
    //         data    : {
    //             requested:json.requested,
    //             generated: json.generated,
    //             items: _.map(tenants),
    //             predefined: _.get(json, "tenants.predefined"),
    //         }
    //     });

    //     postal.publish({
    //         topic   : "vehicles.items.updated",
    //         data    : {
    //             requested:json.requested,
    //             generated: json.generated,
    //             items: _.map(vehicles),
    //         }
    //     });

    //     postal.publish({
    //         topic   : "spaces.items.updated",
    //         data    : {
    //             requested:json.requested,
    //             generated: json.generated,
    //             items: _.map(spaces),
    //             predefined: _.get(json, "spaces.predefined"),
    //         }
    //     });

    //     var result = {
    //         permits: {
    //             items:permits,
    //         },
    //         requested:json.requested,
    //         generated:json.generated,
    //     };
    //     if(!!donotpermit) result.donotpermit = {
    //         items:donotpermit,
    //     };

    //     return result;

    //     /*postal.publish({
    //         topic   : "location.updated",
    //         data    : {
    //             generated: data.generated,
    //             item:location,
    //         }
    //     });

    //     postal.publish({
    //         topic   : "address.updated",
    //         data    : {
    //             generated: data.generated,
    //             item:address,
    //         }
    //     });*/

    // });
    /*
        .catch(function(error) {
            console.log("caught error", error);
        });
        */
  });

  var location = null;

  var _init = _.once(function (ctx) {
    // not sure what we do here...
  });

  var setup = function (ctx, next) {
    _init(ctx);
    next();
  };

  var collection = function (key, items, updated) {
    if (!items) return;

    var list = section.querySelector("ul[data-records='" + key + "']");

    items = _.sortBy(items, function (item) {
      return _.get(item, "vehicle.display") || _.get(item, "tenant.display");
    });

    //console.log("permits=", items);

    list.innerHTML = _.reduce(
      items,
      function (html, item) {
        return html + api.Templates[item.__type](item);
      },
      ""
    );
    list.classList[list.hasChildNodes() ? "remove" : "add"]("empty");

    section
      .querySelector("nav[data-records] a[data-records='" + key + "']")
      .setAttribute("data-records-count", items.length);

    if (!!updated) {
      var time = section.querySelector("time[data-valid]");
      time.setAttribute("datetime", updated);
      time.dispatchEvent(new CustomEvent("change", { bubbles: true }));
    }
  };

  var view = function (result) {
    if (!result) {
      var time = section.querySelector("time[data-valid]");
      time.setAttribute("datetime", "");
      time.dispatchEvent(new CustomEvent("change", { bubbles: true }));
      return;
    }

    _.each(result.items, function (collection, key) {
      if (!collection) return;

      collection(key, collection, result.generated);
    });

    var time = section.querySelector("time[data-valid]");
    time.setAttribute("datetime", result.generated);
    time.dispatchEvent(new CustomEvent("change", { bubbles: true }));
  };

  (function () {
    var last = null;

    postal.subscribe({
      topic: "permits.vehicles.updated",
      callback: function (data, envelope) {
        var ts = new Date(data.generated || data.ts);

        //console.log(last + ", " + ts);

        if (!!last && last.getTime() >= ts.getTime()) return; // this isn't newer

        //console.log(data);

        // sort is responsibility of view
        collection(
          "permits/vehicles/valid",
          _.filter(data.items, api.Permits.isValid),
          data.generated
        );
        collection(
          "permits/vehicles/recent",
          _.reject(data.items, api.Permits.isValid),
          data.generated
        );

        _.each(data.items, function (item) {
          if (!item.sent) return;

          postal.publish({
            topic: "permit.sent.updated",
            data: {
              generated: data.generated,
              item: item.id,
              items: item.sent.items || item.sent,
            },
          });
        });

        last = ts;
      },
    });
  })();

  (function () {
    var last = null;

    postal.subscribe({
      topic: "permittables.donotpermit.updated",
      callback: function (data, envelope) {
        var ts = new Date(data.generated || data.ts);

        if (!!last && last.getTime() >= ts.getTime()) return; // this isn't newer

        collection("permittables/donotpermit", data.items, data.generated);

        last = ts;
      },
    });
  })();

  var load = function (location, donotpermit) {
    return get(location, donotpermit);
  };

  function update() {
    if (!location) return;
    if (root.getAttribute("data-records") !== "permits/vehicles") return; // not on tab
    load(location, true);
  }

  var polling = {
    timer: null,
    interval: 1.5 * 60 * 1000,
    wait: 30 * 1000,
  };
  _.extend(polling, {
    callback: _.throttle(update, polling.wait),
    start: function () {
      polling.timer =
        polling.timer ||
        window.requestInterval(polling.callback, polling.interval);
    },
    stop: function () {
      if (!!polling.timer) window.clearRequestInterval(polling.timer);
      polling.timer = null;
    },
  });

  function index(location) {
    load(location, false).then(update);
    // wire refresh
    polling.start();
  }

  var enter = function (ctx, next) {
    root.setAttribute("data-records", section.getAttribute("data-records"));

    // initially load without banned, then refresh
    index((location = ctx.params.location), false);
  };

  var exit = function (ctx, next) {
    polling.stop();

    //view(); do we actually want to clear the views?

    next();
  };

  _.each(["/:location/permits/vehicles"], function (path) {
    page(path, enter);
    page.exit(path, exit);
  });
})(
  page,
  postal,
  ParkIQ.API,
  document.documentElement,
  document.querySelector("main[data-records='permits/vehicles']")
);

export default self;
