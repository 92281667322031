(function(page, postal, api, root, container) {

	var updated = container.querySelector("time[data-valid]");
	var location;

	function valid(datetime) {
		return Promise.resolve(datetime)
		.then(function(datetime) {
			updated.setAttribute("datetime", !datetime ? "" : !!datetime.toISOString ? datetime.toISOString() : datetime);
        	updated.dispatchEvent(new CustomEvent("update", { "bubbles": true }));
			return datetime;
		})
	}
    
    var collection = function(key, items) {
        if(!items) return;

         var list = container.querySelector("ul[data-records='" + key + "']");
            
        // items = _.sortBy(items, function(item) {
        //     return _.get(item, "vehicle.display") || _.get(item, "tenant.display");
        // });

        list.innerHTML = _.reduce(items, function(html, item) {
            return html + api.Templates.user(item);
        }, "");
        list.classList[list.hasChildNodes() ? "remove" : "add"]("empty");
        
        _.each(container.querySelectorAll("nav[data-records] a[data-records='" + key + "'], nav[data-records] a[href='" + key + "']"), function(elem) {
            elem.setAttribute("data-records-count", items.length);
        });

    }
    
    function view(result) {
        return Promise.resolve(result)
        .then(function(result) {
            return Promise.join(
                collection("users/managers", _.filter(result.items, "roles.admin")),
                collection("users/fieldagents", _.filter(result.items, "roles.fieldagent")),
                valid(result.generated),
                function() {
                    return result;
                }
            );
            /*
            return Promise.join(collection("users/all", result.items), valid(result.generated), function(){
                return result;
            })
            */
            
        });
    };

    (function() {
	
		var last = null;
		
		postal.subscribe({
			topic:"users.updated",
			callback:function(data, envelope) {
				
				var ts = new Date(data.generated || data.ts);
				

				
				if(!!last && last.getTime() >= ts.getTime()) return; // this isn't newer
			
				// feed into the view
				views.index(data.items.sort(function(a, b) {
					return Sorter.asc(a.created.utc || a.created, b.created.utc || b.created);
				}), ts);
				
				last = ts;
			},
	
		});
	}());

    function users(json){
        return Promise.resolve(json)
        .then(function(json) {
            return {
                generated:_.get(json, "generated",""),
                items: _.chain(json)
						.get("users.items", _.get(json, "users") || _.get(json, "items") || [])
                        .map(api.Items.normalize)
                        .map(function(item) {

                            return _.defaults(item, {
                                roles: {
                                    admin: _.get(json, [ "users", "admins", "items", item.id ], false),
                                    fieldagent: _.get(json, [ "users", "patrollers", "items", item.id ], false),
                                },
                                auth: _.get(json, [ "users", "auth", "items", item.id ]),
                            });

                        })
						.sortBy([ "created.utc", "created" ])
						.value(),
            }
        })
        
    }

    function get(location) {

        var requested = new Date().toISOString();
        var url = "v1/locations/" + location + "/users?ts=" + requested;

        return Promise.join(api.base(), location, function(base, location) {
			return api.fetch("GET", base + url, null, api.Auth.header(location));
		})
        .then(function(json) {

            json.requested = requested;
            
            return json;

        });

    }

    var location;

    function load(location) {
        return get(location);
    };

    function update() {
        if(!location) return;
        if(root.getAttribute("data-records") !== container.getAttribute("data-records")) return; // not on tab
        load(location)
        .then(users)
        .then(view);
    }

    _.each([
		"user.created",
		//"user.updated",
		"user.deleted"
	], function(topic) {
		postal.subscribe({
			topic:topic,
			callback:update,
		});
	});

    var polling = {
        timer:null,
        interval: 1.5 * 60 * 1000,
        wait: 30 * 1000,
    };
    _.extend(polling, {
        callback:_.throttle(update, polling.wait),
        start:function() {
            polling.timer = polling.timer || window.requestInterval(polling.callback, polling.interval);
        },
        stop:function() {
            if(!!polling.timer) window.clearRequestInterval(polling.timer);
            polling.timer = null;
        },
    });
    

    function index(location) {
		update();
        polling.start();
	}

    function enter(ctx, next) {
        
        // show a specific violation
        root.setAttribute("data-records", container.getAttribute("data-records"));
        index(location = ctx.params.location);
        
    };
    
    function exit(ctx, next) {

        polling.stop();

        //view(); do we actually want to clear the views?
        
        next();
    }

    _.each([
        "/:location/users"
    ], function(path) {
        page(path, enter);
        page.exit(path, exit);
    });

}(page, postal, ParkIQ.API, document.documentElement, document.querySelector("main[data-records='users']")));

export default self;