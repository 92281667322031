import { writable, derived, readable } from "svelte/store";
import { items } from "@parkingboss/svelte-utils";
import {
  filter,
  merge,
  get,
  pick,
  map,
  has,
  each,
  find,
  every,
  unset,
  orderBy,
  pickBy,
  once,
  uniqBy,
  clone,
} from "lodash-es";
import {
  resolveProperty,
  fetchAndStoreProperty,
  fetchAndStoreVehicles,
  fetchAndStoreSpaces,
  fetchAndStoreMedia,
  fetchAndStoreUnits,
} from "./api";
import store from "store/dist/store.modern";
import { params } from "./params";

const comparer =
  !!window.Intl && !!window.Intl.Collator
    ? new Intl.Collator(undefined, {
        numeric: true,
        sensitivity: "base",
        caseFirst: "lower",
      }).compare
    : (a, b) => (a < b ? -1 : a > b ? 1 : 0);

export const state = items;

export { params };

//export const camera = writable({});
//camera.subscribe(value => console.log("camera=", value));

// make this triggerable from mqtt?

export const propertyId = derived(
  [params],
  ([params]) => params.property && params.property.toLowerCase()
);
export const policyId = derived(
  [params],
  ([params]) => params.policy && params.policy.toLowerCase()
);
export const view = derived([params], ([params]) => params.view);
export const valid = derived([params], ([params]) => params.valid);

async function updateProperty(property) {
  fetchAndStoreProperty(property);
  fetchAndStoreVehicles(property);
  fetchAndStoreMedia(property);
  fetchAndStoreSpaces(property);
  fetchAndStoreUnits(property);
  //fetchAndStorePropertyGeojson(property);
}

// once you're on a property, assumed until the actual value changes
// store previous value and use value equality to prevent frothy requests
let propertyRefresher = null;
let previousPropertyId = null;
propertyId.subscribe(async (value) => {
  //if(!!propertyRefresher) clearInterval(propertyRefresher); // alway stop the scheduler

  if (!value) return; // don't do anything, but keep previous value cached - leave refreshers active

  if (value === previousPropertyId) return; // the assignment changed, but not the actual value;

  // we have a new ID
  if (!!propertyRefresher) clearInterval(propertyRefresher); // stop the previous scheduler

  previousPropertyId = value;

  //console.log("propertyId changed=", value);

  updateProperty(value);
  propertyRefresher = setInterval(() => updateProperty(value), 10 * 60 * 1000);
});

export const property = derived(
  [propertyId, state],
  ([id, items]) =>
    id && (resolveProperty(items[id], items) || store.get(`properties/${id}`))
);

export const lpr = derived(
  property,
  ($property) =>
    !!(
      $property &&
      $property.vehicles &&
      $property.vehicles.recognition &&
      $property.vehicles.recognition.enabled
    )
);

lpr.subscribe(($value) => console.log("lpr=", $value));

//property.subscribe($value => console.log("property=", $value));
property.subscribe(
  ($value) => $value && store.set(`properties/${$value.id}`, $value)
);

export const policies = derived([property, state], ([property, state]) => {
  if (!property) return null;
  if (!state["policies"]) return null;
  var policies = map(
    state["policies"],
    (version, policy) => state[policy] || state[version]
  );
  //console.log("policies=", policies);
  if (!policies.every((item) => !!item)) return null; // not done loading

  return policies
    .filter(
      (item) =>
        !!item && item.scope === property.id && item.amenity === "parking"
    )
    .map((item) => {
      item.statistics =
        get(state, ["statistics", item.id]) ||
        get(state, ["statistics", item.subject]);
      item.meters.items = sortBy(
        map(item.meters.items, (m) => {
          m = state[m] || m;
          if (!m) return m;
          if (m.group === "tenant")
            m.group = get(property, "tenants.format", m.group);
          return m;
        }),
        ["rank"]
      );
      item.property = resolveProperty(item.location, state);
      return item;
    })
    .sort((a, b) => comparer(a.title, b.title));
});

// export const policy = derived([ policyId, policies, state ], ([ id, policies, items ]) => {
//     var item = !!id && !!policies && policies.find(item => item.id === id || item.subject === id);
//     return item;
//     return merge(item, {
//         property: resolveProperty(item.location, items),
//     });
// });

// geojson store
// export const geojson = derived([ property, state ], ([ $property, $items ]) => {
//     return get($items, [ "geojson" ]);
// });
