<script>
  import { geo } from "@parkingboss/svelte-utils";
  import debug from "debug";
  import { get, pick } from "lodash-es";
  import { createEventDispatcher, onMount } from "svelte";
  import { fetchPlateObservation } from "../util/api";
  import {
    current as getOrientation,
    start as startCompass,
  } from "../util/compass";
  import { lpr } from "../util/propertystores";
  import { autocreate } from "../util/vehicle";
  import Camera from "./Camera.svelte";

  const log = debug("boss:field-agent:VehicleScanner");

  export let selected = null;
  export let property;

  const dispatch = createEventDispatcher();

  let preview;
  let cam;
  let compass;

  $: cam?.scrollIntoView();

  onMount(() => {
    compass = startCompass();

    return clearPreview;
  });

  function clearPreview() {
    setPreview(null);
  }

  function setPreview(fileOrBlob) {
    if (preview) {
      URL.revokeObjectURL(preview);
    }
    preview = fileOrBlob && URL.createObjectURL(fileOrBlob);
    //console.log("preview=", preview);
  }

  function select(plate) {
    clearPreview();

    selected = autocreate(plate);
    if (selected) dispatch("vehicle", selected);
  }

  function coordsWithHeading(gps, compass) {
    const p = get(gps, "position.coords");
    const h = get(compass, "orientation");
    if (!p && !h) return;
    return Object.assign(
      {},
      pick(p, [
        "latitude",
        "longitude",
        "accuracy",
        "heading",
        "headingAccuracy",
        "speed",
        "altitude",
        "altitudeAccuracy",
      ]),
      pick(h, ["heading", "headingAccuracy"])
    );
  }

  const position = () =>
    coordsWithHeading(geo && $geo, compass && getOrientation());

  async function detect(photo) {
    if (!photo) return;

    const res = await fetchPlateObservation(property, photo, position());
    const detection = Object.values(get(res, "detections.items", {}))[0];

    return get(res, ["items", detection], detection);
  }

  async function onframe(e) {
    const { blob } = e.detail;
    const photo = blob;

    //console.log({photo});

    setPreview(photo); // set, clear + set, clear

    if (!photo) return;

    const detection = await detect(photo);

    clearPreview();
    if (!detection || !detection.vehicle) return;
    select(detection.vehicle);
  }

  $: log("geo:", $geo);
</script>

{#if !$lpr}
  <figure class="camera error upgrade">
    <figcaption>
      <h1>Update Required</h1>
      <p>Vehicle plate/tag scanning isn't enabled for this account</p>
    </figcaption>
  </figure>
{:else if $geo?.status === "loading" || $geo?.status === "requesting"}
  <figure class="camera error gps">
    <figcaption>
      <h1>Loading</h1>
      <p>
        We're loading your current location, please hold tight, this could take
        a few seconds and up to a minute in some cases.
      </p>
    </figcaption>
  </figure>
{:else if $geo?.status === "error"}
  <figure class="camera error gps">
    <figcaption>
      <h1>An error occurred</h1>
      <p>
        Something went wrong when attempting to get your location. Please try
        reloading the page and trying again. If this persists, please contact
        Community Boss support.
      </p>
      {#if $geo?.error}
        <pre>{$geo.error}</pre>
      {/if}
    </figcaption>
  </figure>
{:else if $geo?.status === "unsupported"}
  <figure class="camera error gps">
    <figcaption>
      <h1>Location not supported</h1>
      <p>
        Your device does not support location services, vehicle plate/tag
        scanning is not available without these features. Please contact
        Community Boss for assistance.
      </p>
    </figcaption>
  </figure>
{:else if $geo?.status === "unpermitted" || !$geo?.position?.coords}
  <figure class="camera error gps">
    <figcaption>
      <h1>Location Required</h1>
      <p>
        Your location must be enabled and active to use vehicle plate/tag
        scanning
      </p>
    </figcaption>
  </figure>
{:else}
  <Camera bind:this={cam} class="vehicle" cover={preview} on:frame={onframe} />
{/if}
