(function(root, Delegate, api, postal, page) {

    _.defer(function() {
    
        var delegate = new Delegate(root);
        
        delegate.on("submit", "form[data-record='note']", function(e) {
            
            e.preventDefault();

            var form = this;

            var disable = Array.prototype.slice.call(form.querySelectorAll("button,input,textarea,select")).filter(function(input) {
                input.blur();
                return !input.disabled;
            });
            
            if(disable.filter(function(input) { return !!input.required && !input.value; }).length > 0) return; // required with no value
        
            
            if (form.classList.contains("submitting")) return;
            form.classList.add("submitting");
            
            var formData = new FormData(form);
            //var formData = serialize(form);

            // disable
            disable.forEach(function(input) { input.disabled = true; });
            
            var requested = new Date().toISOString()
            var url = "/notes?ts=" + requested;
            var min = Promise.delay(2000);

            return Promise.join(api.base(), api.Location.id(), function(base, location) {
                return api.fetch("POST", base + "v1/locations/" + location + url, formData, api.Auth.header(location));
            })
            .then(function(json) {

                json.requested = requested;
                
                var item = json.notes.item;
                item.__type = "note";
                api.Items.user(item.issued, json.users.items || json.users);
                api.Items.normalize(item);
                
                
                postal.publish({
                    topic:"note.created",
                    data    : {
                        generated: json.generated,
                        item:item,
                    },
                });

                return {
                    item:item,
                    generated:json.generated,
                };


            })
            .then(function() {
                return min;
            })
            .then(function() {
                
                form.classList.remove("submitting");
                disable.forEach(function(input) { input.disabled = false; });
                if(form.classList.contains("repeat")) form.classList.add("initing");
                form.reset();
                
            }, function(error) {
                
                form.classList.remove("submitting");
                disable.forEach(function(input) { input.disabled = false; });

                console.log("caught error", error);
                
            });
            
        });

    });
    
}(document.documentElement, window.Delegate || window.domDelegate.Delegate, ParkIQ.API, window.postal));

export default self;