<script>
  import Camera from "./Camera.svelte";

  import { createEventDispatcher, onMount } from "svelte";
  import { get, pick } from "lodash-es";
  import { geo } from "@parkingboss/svelte-utils";
  import { infotag } from "@parkingboss/utils";

  import {
    start as startCompass,
    current as getOrientation,
  } from "../util/compass";
  import { fetchMediaObservation } from "../util/api";

  export let selected = null;
  export let property;

  const dispatch = createEventDispatcher();

  let preview;
  let cam;
  let compass;

  $: cam?.scrollIntoView();

  onMount(() => {
    compass = startCompass();

    return clearPreview;
  });

  function clearPreview() {
    setPreview(null);
  }

  function setPreview(fileOrBlob) {
    if (preview) {
      URL.revokeObjectURL(preview);
    }
    preview = fileOrBlob && URL.createObjectURL(fileOrBlob);
    //console.log("preview=", preview);
  }

  function select(media) {
    clearPreview();

    selected = media;
    if (selected) dispatch("media", selected);
  }

  function coordsWithHeading(gps, compass) {
    const p = get(gps, "position.coords");
    const h = get(compass, "orientation");
    if (!p && !h) return;
    return Object.assign(
      {},
      pick(p, [
        "latitude",
        "longitude",
        "accuracy",
        "heading",
        "headingAccuracy",
        "speed",
        "altitude",
        "altitudeAccuracy",
      ]),
      pick(h, ["heading", "headingAccuracy"])
    );
  }

  const position = () =>
    coordsWithHeading(geo && $geo, compass && getOrientation());

  async function delayClearPreview(sec) {
    await new Promise((r) => setTimeout(r, sec * 1000));
    clearPreview();
  }

  let last;

  async function onbarcode(e) {
    // If we're already processing, don't process more than one at a time.
    if (preview) return;

    const { barcode, blob } = e.detail;

    const photo = blob;

    if (!photo || !barcode || barcode == last) return clearPreview();

    setPreview(photo);

    if (!barcode) {
      return await delayClearPreview(1);
    }

    last = barcode;

    const value = infotag.parse(barcode);
    if (!value || "media" != value.format) {
      return await delayClearPreview(1);
    }

    // fetch the media obs
    const json = await fetchMediaObservation(
      property,
      photo,
      value.id,
      position()
    );
    var observation =
      json?.items?.[json?.observations?.item] ||
      json?.observations?.items?.[json?.observations?.item] ||
      json.observations.item;

    if (typeof observation == "string") observation = null; // couldn't resolve an object

    if (!observation) {
      return clearPreview();
    }

    const media =
      (observation.media && json.items[observation.media]) || observation.media;

    // couldn't resolve this media
    if (!media || !media.id) {
      alert("This Smart Decal belongs to another location");
      return clearPreview();
    }

    return select(media);
  }
</script>

{#if property?.media?.format != "smart-decal"}
  <figure class="camera error gps">
    <figcaption>
      <h1>Update Required</h1>
      <p>Smart Decals aren't enabled for this account</p>
    </figcaption>
  </figure>
{:else}
  <Camera
    bind:this={cam}
    class="smart-decal"
    barcode
    cover={preview}
    on:barcode={onbarcode}
  />
{/if}
