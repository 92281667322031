export function autocreate(key) {
    if(!key || typeof key !== "string") return key;
    key = key.replace("O", "0");
    if(key.length <= 2) return null;
    if(key.length >= 10) return null;
    return {
        type:"vehicle",
        key:key,
        display:key,
    };
}