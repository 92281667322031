(function (root, api, page, Delegate, postal) {
  var summaries = root.querySelectorAll(
    "section[data-records='violations/exceptions'] > header p"
  );
  var lists = root.querySelectorAll("ul[data-records='violations/exceptions']");
  var updated = root.querySelectorAll(
    "section[data-records='violations/exceptions'] time[data-valid]"
  );
  var links = root.querySelectorAll("a[href='violations/exceptions']");
  var location;
  var loaded;

  var views = {
    index: function (items, valid) {
      _.each(lists, function (list) {
        views.list(list, items);
      });

      _.each(updated, function (updated) {
        updated.setAttribute(
          "datetime",
          !!valid.toISOString ? valid.toISOString() : valid
        );
        updated.dispatchEvent(new CustomEvent("update", { bubbles: true }));
      });
    },
    list: function (list, items) {
      list.classList.remove("empty");

      if (!items) {
        while (!!list.firstChild) list.removeChild(list.firstChild);
        return;
      }

      // item sorting...
      if (list.classList.contains("timeline"))
        items = _.orderBy(items, ["latest"], ["desc"]);
      else items = _.orderBy(items, ["count"], ["desc"]);

      list.innerHTML = _.reduce(
        items,
        function (html, item) {
          return html + ParkIQ.API.Templates[item.__type](item);
        },
        ""
      );
      if (!list.hasChildNodes()) list.classList.add("empty");
    },
    edit: function (threshold) {},
  };

  function view(result) {
    var count = _.get(result, "count", 0);
    var enabled = true; // _.get(result, "enabled", true);

    _.each(lists, function (el) {
      el.setAttribute("data-enabled", enabled.toString());
      el.setAttribute("data-records-count", count.toString());
    });

    views.index(result.exceptions, result.generated);
    // set the info

    _.each(summaries, function (elem) {
      elem.innerHTML = _.map(result.thresholds, function (item) {
        return item.display;
      }).join(", ");
    });

    _.each(links, function (a) {
      a.setAttribute("data-records-count", count.toString());
    });

    return result;
  }

  function process(json) {
    var thresholds = _.get(json, "violations.thresholds.items", []);
    var exceptions = _.get(json, "violations.exceptions.items", []);

    return {
      requested: json.requested,
      generated: json.generated,
      location: _.get(json, [
        "locations",
        "items",
        _.get(json, "locations.item"),
      ]),
      thresholds: thresholds,
      enabled: true, //_.get(json, "violations.exceptions.enabled") !== false,
      count: _.get(json, "violations.exceptions.count"),
      exceptions: _.chain(exceptions)
        .map(function (item) {
          item.__type = "exception";
          item.threshold = _.get(
            json,
            thresholds,
            item.threshold,
            item.threshold
          );

          if (!!item.subject)
            item.subject = _.get(json, ["items", item.subject], item.subject);
          if (
            !!item.subject &&
            !item.vehicle &&
            item.subject.type === "vehicle"
          )
            item.vehicle = item.subject;
          if (!!item.subject && !item.tenant && item.subject.type === "tenant")
            item.tenant = item.subject;
          //if(!!item.id && !item.vehicle && !item.tenant) item.tenant = _.get(json, [ "tenants", "items", item.id ]);
          //console.log("exception=", item);
          return item;
        })
        //.orderBy([ "count" ], [ "desc" ])
        .value(),
    };
  }

  if (!!postal)
    postal.subscribe({
      //channel  : "location",
      topic: "violations.exceptions",
      callback: function (data, envelope) {
        if (!data) return;
        // save new on update
        var processed = process(data);

        //console.log(process);

        if (!processed) return;

        location = _.get(processed, "location.id", location);

        if (!!loaded && loaded.generated > processed.generated) return;
        view((loaded = processed));
      },
    });

  function get(location) {
    var requested = new Date().toISOString();

    return api.fetch(
      "GET",
      Promise.join(api.base(), location, function (base, location) {
        return (
          base +
          "v1/locations/" +
          location +
          "/violations/exceptions?valid=" +
          requested
        );
      }),
      null,
      api.Auth.header(location)
    );
  }

  function load(location) {
    return get(location)
      .then(process)
      .then(function (result) {
        return (loaded = result);
      });
  }

  var update = _.debounce(
    function () {
      //if(!!id) location = id;
      if (!location) return;
      if (!root.matches("[data-records^='violations']")) return; // not on tab
      load(location).then(view);
    },
    30 * 1000,
    {
      leading: true,
    }
  );
})(
  document.documentElement,
  ParkIQ.API,
  page,
  window.Delegate || window.domDelegate.Delegate,
  window.postal
);

export default self;
